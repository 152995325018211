export const addressModelStateMapper = (address) => ({
  subdivision_code: address.subdivision_code || undefined,
  country: address.country_code || undefined,
  line_1: address.line_1 || '',
  line_2: address.line_2 || '',
  locality: address.locality || '',
  postal_code: address.postal_code || '',
});

const cleanNullKeys = (obj) =>
  Object.keys(obj)
    .filter((key) => !!obj[key] || obj[key] === false)
    .reduce((res, key) => {
      res[key] = obj[key];
      return res;
    }, {});

export const addressStateModelMapper = (
  { line_1, line_2, locality, postal_code, subdivision_code },
  keyName
) => {
  if (
    [line_1, line_2, locality, postal_code, subdivision_code].find(
      (val) => !!val
    )
  ) {
    return {
      [keyName]: cleanNullKeys({
        line_1,
        line_2,
        locality,
        postal_code,
        subdivision_code,
      }),
    };
  }

  return null;
};
