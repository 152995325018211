/**
 * This is the primary locale settings source
 * All locale settings for the application are initialized here
 * those include:
 *
 * In Profile Settings:
 * – Time Format
 * – Date Format
 * – Time Zone
 * In Organization Settings:
 * – Currency
 * – Country Code
 * – Address Style (Universal/US)
 * – Measurement Units (Metric/Imperial)
 */

import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { GetOrganizationLocaleSettings, userCurrent } from 'gql/queries';
import { useApolloClient } from 'react-apollo';
import { useRouter } from 'next/router';
import { Organization } from 'types/organization';
import { Person, PersonOrganizationPermission, User } from 'types/person';
import { Loader } from 'components/loader';
import { deleteToken, userCookieExist } from 'lib/auth';
import { permissionsOrganizationsMapper } from 'helpers/data-mappers/organisationMapper';
import { getCookie, setCookie } from 'lib/session';
import flagsmith from 'flagsmith';
import { toast } from 'react-toastify';
import { createStrictContext } from 'helpers/createStrictContext';
import { computersTimezone, LocaleSettings } from 'helpers/localization';

export type OrganizationLocaleSettings = {
  currency: string;
  unitsOfMeasurement: 'IMPERIAL' | 'METRIC';
  countryCodePrefix: string;
  addressStyle: 'US' | 'UNIVERSAL';
};

export type UserContextType = {
  user: Person | null;
  organizations: Organization[];
  activeOrganization: Organization | null;
  user_id: null | string;
  localeSettings: LocaleSettings | null;
  refreshUserData(): Promise<void>;
};

export const [
  UserContext,
  useUserContext,
] = createStrictContext<UserContextType>({
  user: null,
  organizations: [],
  activeOrganization: null,
  user_id: null,
  localeSettings: null,
  refreshUserData: () => Promise.resolve(void 0),
});

type UserCurrentResponse = {
  user_current: User;
  user_permissions: PersonOrganizationPermission[];
};

type OrganizationSettingsResponse = {
  organization_by_id: {
    settings: {
      address_style: 'US' | 'UNIVERSAL';
      phone_country_code: string;
      default_currency: string;
      measurement_system: 'IMPERIAL' | 'METRIC';
    };
  };
};

function FlagsmithUserIdentifier({
  userId,
  organizations,
}: {
  userId: string | undefined;
  organizations: Organization[];
}): null {
  useEffect(() => {
    if (userId) {
      flagsmith.identify(userId);
    }
    flagsmith.setTrait(
      'organizations',
      organizations.map(({ id }) => id).join(', ')
    );
  }, [userId, organizations]);

  return null;
}

export function UserProvider({ children }: PropsWithChildren<{}>): JSX.Element {
  const router = useRouter();
  const client = useApolloClient();
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState<UserCurrentResponse>();
  const [
    organizationSettings,
    setOrganizationSettings,
  ] = useState<OrganizationSettingsResponse>();
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [activeOrganization, setActiveOrganization] = useState<Organization>();

  const initialize = useCallback(async () => {
    try {
      // Check if the user is logged in, and if they aren't we bail out
      // early and redirect them to a login page
      if (!userCookieExist()) {
        await router.push(`/login?forward_url=${window.location.pathname}`);
        return;
      }

      // Now that we know the user is logged in, we fetch user data
      const { data: userData } = await client.query<UserCurrentResponse>({
        query: userCurrent,
        fetchPolicy: 'network-only',
      });

      // From userData and with the organization cookie, we determine the active
      // organization for the user
      const { user_permissions } = userData;

      // This should not happen. This is a bad coding practice.
      // This only happens to let the app work for superadmins who have an undefined permission
      const organizations =
        (permissionsOrganizationsMapper(user_permissions).filter(
          (x: any) => x !== undefined
        ) as Organization[]) || [];

      const activeOrganization =
        organizations.find(({ id }) => id === getCookie('organization')) ||
        organizations[0];

      setCookie('organization', activeOrganization?.id);

      // Now that we have an active organization, we can fetch its information as well
      if (activeOrganization) {
        const {
          data: activeOrganizationSettings,
        } = await client.query<OrganizationSettingsResponse>({
          query: GetOrganizationLocaleSettings,
          variables: { organizationId: activeOrganization.id },
        });
        setOrganizationSettings(activeOrganizationSettings);
      }

      // Lastly, we can persist this all to state
      setUserData(userData);
      setOrganizations(organizations);
      setActiveOrganization(activeOrganization);
      setLoading(false);
    } catch (err) {
      // If there was an error with the network request, delete the user token and redirect to login
      deleteToken();
      toast('Please log in to continue, redirecting to login page', {
        type: 'error',
      });
      await router.push('/login');
      throw err;
    }
  }, [router, client]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  if (loading) {
    return <Loader />;
  }

  const {
    user_current: { profile, id: userId, settings: userLocaleSettings },
  } = userData!;

  const orgLocaleSettings = organizationSettings?.organization_by_id?.settings;

  return (
    <UserContext.Provider
      value={{
        user: profile,
        organizations: organizations!,
        activeOrganization: activeOrganization || null,
        user_id: userId || null,
        localeSettings: {
          dateFormat: userLocaleSettings?.date_format || 'MMDDYYYY',
          timeFormat: userLocaleSettings?.time_format || '12',
          timezone: userLocaleSettings?.timezone || computersTimezone,
          currency: orgLocaleSettings?.default_currency || 'USD',
          unitsOfMeasurement:
            orgLocaleSettings?.measurement_system || 'IMPERIAL',
          countryCodePrefix: orgLocaleSettings?.phone_country_code || '1',
          addressStyle: orgLocaleSettings?.address_style || 'US',
        } as LocaleSettings,
        refreshUserData: () => {
          return initialize();
        },
      }}
    >
      <FlagsmithUserIdentifier userId={userId} organizations={organizations!} />
      {children}
    </UserContext.Provider>
  );
}
