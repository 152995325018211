// @flow
import { addressStateModelMapper } from './fragments/addressMapper';

export const permissionsOrganizationsMapper = (user_permissions /*: Object*/) =>
  user_permissions.map(({ organization, role }) => {
    // eslint-disable-line array-callback-return
    if (organization === undefined || organization === null) {
      return; // eslint-disable-line array-callback-return
    }
    organization.name = organization.name || '';
    organization.role = role || false;
    return organization;
  });

const organisationStateModelMapper = (state) => ({
  id: state.id,
  name: state.name,
  phone: state.phone,
  email: state.email,

  mission_statement: state.mission_statement,
  website: state.website,
  about: state.about,
  logo: state.newImages ? { id: state.newImages } : undefined,
  ...addressStateModelMapper(state, 'primary_address'),
});

export const externalOrganizationStateModelMapper = (
  organization_id /*: string*/,
  state /*: Object*/
) /*: Object*/ => ({
  organization_id,
  add_external_organizations: [organisationStateModelMapper(state)],
});
