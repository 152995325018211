import gql from 'graphql-tag';
import { OrganizationUser } from 'types/organization';
import {
  PetFragment,
  PetIndexFragment,
  AddressFragment,
  PersonFragment,
  OrgFragment,
} from './fragments';
import { ClinicFragment } from './entities/clinic-vet';
import { PetEventType } from 'types/pet';

export const userCurrent = gql`
  {
    user_current {
      id
      profile {
        ...PersonFragment
      }
      settings {
        text_notifications
        email_notifications
        in_app_notifications
        date_format
        time_format
        timezone
      }
      date_registered
    }
    user_permissions {
      role
      organization {
        ...OrgFragment
      }
    }
  }
  ${OrgFragment}
  ${PersonFragment}
`;

export const OrganizationPets = gql`
  query organizationPets(
    $id: UUID!
    $filter: Input_PetFilter
    $pagination: Input_Organization_Pet_Pagination
    $next: String
    $search: String
  ) {
    organization_pets2(
      organization_id: $id
      filter: $filter
      pagination: $pagination
      next_token: $next
      search: $search
    ) {
      total_count
      limit
      offset
      next_token
      total_unfiltered_count
      entities {
        ...PetIndexFragment
      }
    }
  }
  ${PetIndexFragment}
`;

export const MyFamily = gql`
  query MyFamily($id: UUID!) {
    organization_pets2(organization_id: $id) {
      entities {
        id
        internal_id
        pet {
          id
          name
        }
      }
    }
  }
`;

export const OrganizationPeople = gql`
  query organizationPeople(
    $id: UUID!
    $pagination: Input_Person_Pagination
    $next: String
    $search: String
    $filter: Input_PersonFilter
  ) {
    organization_persons2(
      organization_id: $id
      pagination: $pagination
      next_token: $next
      search: $search
      filter: $filter
    ) {
      total_count
      limit
      offset
      next_token
      entities {
        ...PersonFragment
      }
    }
  }
  ${PersonFragment}
`;

export const orgPeopleById = gql`
  query organizationPeople($id: UUID) {
    organization_by_id(id: $id) {
      id
      name
      settings {
        external_persons {
          ...PersonFragment
        }
      }
    }
  }
  ${PersonFragment}
`;

export const ApplicationKeys = gql`
  query applicationKeys($id: UUID) {
    organization_by_id(id: $id) {
      id
      settings {
        foster_application_key
        adoption_application_key
        surrender_application_key
        volunteer_application_key
      }
    }
  }
`;

export const ApplicationKeysAndSchema = gql`
  query applicationKeysAndSchema($id: UUID) {
    organization_by_id(id: $id) {
      id
      settings {
        foster_application_key
        adoption_application_key
        surrender_application_key
        volunteer_application_key
        foster_application_schema
        adoption_application_schema
        surrender_application_schema
        volunteer_application_schema
        surrender_application_schema
      }
    }
  }
`;

export const organizationQuery = gql`
  query organizationName($id: UUID) {
    organization_by_id(id: $id) {
      ...OrgFragment
      settings {
        ...ClinicFragment
        foster_application_key
        adoption_application_key
        surrender_application_key
        volunteer_application_key
        foster_application_code
        adoption_application_code
        surrender_application_code
        volunteer_application_code
        pet_finder_user
        pet_finder_password
        adopt_a_pet_user
        adopt_a_pet_password
        stripe_customer_id
        stripe_subscription_id
      }
    }
  }
  ${ClinicFragment}
  ${OrgFragment}
`;

export const organizationUsersQuery = gql`
  query organizationUsers(
    $id: UUID!
    $filter: OrganizationUsersFilter
    $pagination: Pagination
  ) {
    organization_users(
      organization_id: $id
      filter: $filter
      pagination: $pagination
    ) {
      id
      roles
      profile {
        id
        activities
        photo {
          id
          url
        }
        first_name
        last_name
        email
        phone
        state_id
        address {
          ...AddressFragment
        }
      }
    }
  }
  ${AddressFragment}
`;
export interface OrganizationUsersQueryData {
  organization_users: Array<OrganizationUser>;
}
export interface OrganizationUsersQueryVariables {
  id: string;
  filter?: { user_role: string | null };
  paginations?: {
    limit: number;
    sort_order: null | string;
    sort_key: null | string;
  };
}

export const OrganizationPetQuery = gql`
  query organizationPet($id: UUID!, $organizationId: UUID!) {
    organization_pet_by_id(id: $id, organization_id: $organizationId) {
      id
      ...PetFragment
    }
  }
  ${PetFragment}
`;

export const OrganizationById = gql`
  query organizationById($id: UUID!) {
    organization_by_id(id: $id) {
      id
      name
      settings {
        foster_application_key
        adoption_application_key
        surrender_application_key
        volunteer_application_key
        external_persons {
          id
          first_name
          last_name
          state_id
          email
          phone
          address {
            ...AddressFragment
          }
        }
        external_organizations {
          id
          name
        }
      }
    }
  }
  ${AddressFragment}
`;

export const GetClinicAndVet = gql`
  query organizationById($id: UUID!) {
    organization_by_id(id: $id) {
      id
      settings {
        clinics {
          id
          name
          vets {
            name
          }
        }
      }
    }
  }
`;

export const ExportPDF = gql`
  query ExportPDF($organization_id: UUID!, $organization_pet_id: UUID!) {
    export_organization_pet(
      organization_id: $organization_id
      organization_pet_id: $organization_pet_id
    ) {
      pdf_url
    }
  }
`;

export const myInfo = gql`
  {
    user_current {
      profile {
        id
        email
        first_name
        last_name
        photo {
          url
        }
      }
    }
  }
`;

export const FosterHistory = gql`
  query fosterHistory($person_id: UUID!, $organization_id: UUID!) {
    person_foster_history(
      person_id: $person_id
      organization_id: $organization_id
    ) {
      organization_pet {
        id
        pet {
          id
          name
          images {
            url
          }
        }
      }
      end_date
      start_date
    }
  }
`;
export interface FosterHistoryResult {
  person_foster_history: Array<{
    end_date: string;
    start_date: string;
    organization_pet: {
      id: string;
      pet: {
        id: string;
        name: string;
        images: Array<{
          url: string;
        }>;
      };
    };
  }>;
}

export const AdoptionHistory = gql`
  query adoptionHistory($person_id: UUID!, $organization_id: UUID!) {
    person_adoption_history(
      person_id: $person_id
      organization_id: $organization_id
    ) {
      id
      event_type
      date
      pet {
        id
        name
        images {
          url
        }
      }
    }
  }
`;
export interface AdoptionHistoryResult {
  person_adoption_history: Array<{
    id: string;
    event_type: PetEventType;
    date: string;
    pet: {
      id: string;
      name: string;
      images: Array<{
        url: string;
      }>;
    };
  }>;
}

export const ExternalEntities = gql`
  query externalEntities($id: UUID!) {
    organization_by_id(id: $id) {
      id
      settings {
        external_persons {
          id
          first_name
          last_name
          email
          phone
        }
        external_organizations {
          id
          name
        }
      }
    }
    organization_users(organization_id: $id) {
      id
      profile {
        id
        first_name
        last_name
        email
        phone
      }
    }
  }
`;

export const GetNotifications = gql`
  query userNotifications(
    $id: UUID!
    $pagination: Input_Notifications_Pagination
    $next: String
  ) {
    user_notifications2(pagination: $pagination, next_token: $next) {
      total_count
      limit
      offset
      next_token
      entities {
        date
        id
        title
        message
        is_read
        is_sent
        ... on Notification_MedicalEvent {
          pet_medical_event {
            id
            pet {
              id
            }
          }
        }
        recipient {
          id
          first_name
          last_name
          email
        }
      }
    }
  }
`;

export const GetNotificationCount = gql`
  query GetNotificationCount($id: UUID!) {
    user_notifications2 {
      entities {
        is_read
      }
    }
  }
`;

export const GetOrganizationLocaleSettings = gql`
  query getOrganizationLocaleSettings($organizationId: UUID) {
    organization_by_id(id: $organizationId) {
      id
      settings {
        address_style
        phone_country_code
        default_currency
        measurement_system
      }
    }
  }
`;
