import gql from 'graphql-tag';

export const ClinicFragment = gql`
  fragment ClinicFragment on Organization_Settings {
    clinics {
      id
      name
      vets {
        name
      }
    }
  }
`;

export const updateOrganizationClinics = gql`
  mutation($input: Input_Organization_Settings_Update!) {
    update_organization_settings(input: $input) {
      ...ClinicFragment
    }
  }
  ${ClinicFragment}
`;
