/**
 * formatPhoneNumber formats incoming phone string containing
 * country code and phone number separated by a space and spits out
 * joined number with plus sign appended to front
 *
 * localizeDate takes in date in UTC and localeSettings with dateFormat and
 * returns full properly formatted date
 *
 * getLocalizedTimeFormat takes in localeSettings and returns
 * correct time format accordingly
 *
 * dateFormat takes in date format string and returns a date format string
 * that's required for format property in DatePicker components
 */

import { parseISO, startOfDay, sub, format } from 'date-fns';
import { getTimezoneOffset, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import moment from 'moment';

export type DateFormat = 'MMDDYYYY' | 'YYYYMMDD' | 'DDMMYYYY';
export type TimeFormat = '12' | '24';
export type Timezone = string; // TODO:make this a union of accepted values

export const TIMEZONE = [
  { label: 'Pacific/Honolulu', value: 'Pacific/Honolulu' },
  { label: 'America/Anchorage', value: 'America/Anchorage' },
  { label: 'Pacific Time/Los Angeles', value: 'America/Los_Angeles' },
  { label: 'Mountain Time/Phoenix', value: 'America/Boise' },
  { label: 'Central Time/Chicago', value: 'America/Chicago' },
  { label: 'Eastern Time/New York', value: 'America/New_York' },
  { label: 'America/Caracas', value: 'America/Caracas' },
  { label: 'America/Puerto Rico', value: 'America/Puerto_Rico' },
  { label: 'America/St Johns', value: 'America/St_Johns' },
  { label: 'America/Buenos Aires', value: 'America/Buenos_Aires' },
  { label: 'Atlantic/Cape Verde', value: 'Atlantic/Cape_Verde' },
  { label: 'Atlantic/Reykjavik', value: 'Atlantic/Reykjavik' },
  { label: 'Europe/Dublin', value: 'Europe/Dublin' },
  { label: 'Africa/Tripoli', value: 'Africa/Tripoli' },
  { label: 'Europe/Moscow', value: 'Europe/Moscow' },
  { label: 'Asia/Dubai', value: 'Asia/Dubai' },
  { label: 'Asia/Tehran', value: 'Asia/Tehran' },
  { label: 'Asia/Kabul', value: 'Asia/Kabul' },
  { label: 'Asia/Karachi', value: 'Asia/Karachi' },
  { label: 'Asia/Kolkata', value: 'Asia/Kolkata' },
  { label: 'Asia/Kathmandu', value: 'Asia/Kathmandu' },
  { label: 'Asia/Almaty', value: 'Asia/Almaty' },
  { label: 'Asia/Rangoon', value: 'Asia/Rangoon' },
  { label: 'Asia/Jakarta', value: 'Asia/Jakarta' },
  { label: 'Asia/Hong Kong', value: 'Asia/Hong_Kong' },
  { label: 'Asia/Tokyo', value: 'Asia/Tokyo' },
  { label: 'Australia/Darwin', value: 'Australia/Darwin' },
  { label: 'Australia/Canberra', value: 'Australia/Canberra' },
  { label: 'Pacific/Guam', value: 'Pacific/Guam' },
  { label: 'Pacific/Guadalcanal', value: 'Pacific/Guadalcanal' },
  { label: 'Pacific/Auckland', value: 'Pacific/Auckland' },
  { label: 'Pacific/Apia', value: 'Pacific/Apia' },
] as const;

export const TIMEFORMAT = [
  { label: '12 hour ( AM / PM )', value: '12' },
  { label: '24 hour ( 16:30 )', value: '24' },
] as const;

export const DATEFORMAT = [
  { label: 'MM / DD / YYYY', value: 'MMDDYYYY' },
  { label: 'DD / MM / YYYY', value: 'DDMMYYYY' },
  { label: 'YYYY / MM / DD', value: 'YYYYMMDD' },
];

export type LocaleSettings = {
  dateFormat: DateFormat;
  timeFormat: TimeFormat;
  timezone: Timezone;
  currency: string;
  unitsOfMeasurement: 'IMPERIAL' | 'METRIC';
  countryCodePrefix: string;
  addressStyle: 'US' | 'UNIVERSAL';
};
export const formatPhoneNumber = (ph: string | null): string => {
  if (ph == null) return '';
  const parts = ph.split(' ');
  // legacy numbers that have exactly one space in them will have "+" added to the front of number;
  if (parts.length !== 2) return ph;
  return '+' + parts.join('');
};

export function localizeDate(
  date: string,
  localeSettings: LocaleSettings
): string {
  switch (localeSettings.dateFormat) {
    case 'DDMMYYYY':
      return format(parseISO(date), 'dd/MM/yyyy');
    case 'YYYYMMDD':
      return format(parseISO(date), 'yyyy/MM/dd');
    default:
      return format(parseISO(date), 'MM/dd/yyyy');
  }
}

export function getLocalizedTimeFormat(localeSettings: LocaleSettings): string {
  if (localeSettings.timeFormat === '24') {
    return 'H:mm';
  } else {
    return 'h:mm aaaa';
  }
}

export function localizeTime(
  time: string,
  localeSettings: LocaleSettings
): string {
  if (localeSettings.timezone) {
    return format(
      utcToZonedTime(parseISO(time), localeSettings.timezone),
      `${getLocalizedTimeFormat(localeSettings)}`
    );
  } else {
    return format(parseISO(time), `${getLocalizedTimeFormat(localeSettings)}`);
  }
}

export const dateFormat = (format: string) => {
  if (format === 'YYYYMMDD') {
    return 'yyyy/MM/dd';
  } else if (format === 'DDMMYYYY') {
    return 'dd/MM/yyyy';
  } else {
    return 'MM/dd/yyyy';
  }
};

const translations = {
  line1({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Address (line 1)' : 'Street 1';
  },
  line2({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Address (line 2)' : 'Street 2';
  },
  locality({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'City / Town' : 'City';
  },
  postal_code({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Zip code / Postal code' : 'Zip code';
  },
  state_code({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'State / Province' : 'State';
  },
  country_code({ addressStyle }: LocaleSettings) {
    return addressStyle === 'UNIVERSAL' ? 'Country / Territory' : 'Country';
  },
};
/**
 * `localize` is our minimal translation interface. It is similar ot the `t`
 * function often found in localization libraries. While we are not yet
 * localized and have minimal internationalization functionality, we have set up
 * a similar function to reduce the cost on maintenance for us and have a
 * convenient path forward to full i18n someday without paying the cost for it
 * now.
 *
 * Usage:
 *   localize('line1', localeSettings); // returns string
 *   localize('non-existent-key', localeSettings); // throws TypeError
 */
export function localize(
  key: keyof typeof translations,
  localeSettings: LocaleSettings
): string {
  if (!translations.hasOwnProperty(key)) {
    throw new TypeError(`No translation key for "${key}"`);
  }

  if (typeof translations[key] === 'function') {
    return translations[key](localeSettings);
  } else {
    return (translations[key] as unknown) as string;
  }
}

export function isValidDate(date: Date) {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    return !isNaN(date.getTime());
  }
  return false;
}

/**
 * Converts a javascript date object to a date string at midnight in the
 * user-selected time zone for use in the day picker component. String is returned
 * in user-selected date format
 *
 * @param date
 * @param localeSettings
 * @return string
 */
export function convertDateToDayPickerString(
  date: Date,
  localeSettings: LocaleSettings
) {
  const dateInUserSettingTimeZone = utcToZonedTime(
    date,
    localeSettings.timezone
  );
  return format(
    dateInUserSettingTimeZone,
    dateFormat(localeSettings.dateFormat)
  );
}

/**
 * Converts a string representation of a date in the user-selected date format
 * in the user-selected time zone at midnight to a Javascript Date object at
 * midnight in the user-selected time zone
 *
 * @param date
 * @param localeSettings
 * @return Date
 */
export function unConvertDayPickerDateString(
  date: string,
  localeSettings: LocaleSettings
) {
  const safeDateString = translateDayPickerStringIntoDateConstructorString(
    date,
    localeSettings
  );
  return zonedTimeToUtc(safeDateString, localeSettings.timezone); //midnight in user setting time zone
}

export function getRightNowInUserSettingLocale(localeSettings: LocaleSettings) {
  const rightNow = new Date().toJSON();

  return utcToZonedTime(rightNow, localeSettings.timezone);
}

export function getTimeInUtcFromLocale(
  time: string,
  localeSettings: LocaleSettings
) {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1;
  const monthString = month.toString().padStart(2, '0');
  const day = currentDate.getDate();
  const dayString = day.toString().padStart(2, '0');
  const isoTime = `${year}-${monthString}-${dayString} ${time}`;
  //must give zonedTimeToUtc a date string or it uses browser tz
  const utcDate = zonedTimeToUtc(isoTime, localeSettings.timezone);
  const hours = utcDate.getUTCHours().toString().padStart(2, '0');
  const minutes = utcDate.getUTCMinutes().toString().padStart(2, '0');
  return `${hours}:${minutes}`;
}

/**
 * Returns a Date object at midnight in the user-selected time zone today.
 * Note that today in the time zone selected may be tomorrow or yesterday based
 * on computer time
 *
 * @param localeSettings
 * @return Date
 */
export function getMidnightRightNowInUserSettingLocale(
  localeSettings: LocaleSettings
) {
  const browserOffsetInMinutes = new Date().getTimezoneOffset();

  const userLocaleSettingOffsetInMs = getTimezoneOffset(
    localeSettings.timezone
  );
  const userLocaleSettingsOffsetInMinutes =
    userLocaleSettingOffsetInMs / 1000 / 60;

  const rightNow = new Date().toJSON();

  const rightNowInUserSettingsTimeZone = utcToZonedTime(
    rightNow,
    localeSettings.timezone
  );

  const beginningOfDayInUserSettingsTimeZone = startOfDay(
    rightNowInUserSettingsTimeZone
  );

  return sub(beginningOfDayInUserSettingsTimeZone, {
    minutes: browserOffsetInMinutes + userLocaleSettingsOffsetInMinutes,
  });
}

export function timeZoneToBrowserTime(date: Date, timeZone: string) {
  return zonedTimeToUtc(date, timeZone);
}

export function browserTimeToZoneTime(date: Date, timeZone: string) {
  return utcToZonedTime(date, timeZone);
}

/**
 * timezone of computer
 */
export const computersTimezone = Intl.DateTimeFormat().resolvedOptions()
  .timeZone;

/**
 * Takes in a day picker string in the user-selected time format and returns
 * a string with which it is safe to construct a date object
 *
 * @param date
 * @param localeSetting
 * @return string
 */
export function translateDayPickerStringIntoDateConstructorString(
  date: string,
  localeSetting: LocaleSettings
) {
  // moment's parse function is less rigid than that of date-fns. It allows user input
  // to contain lots of variance. It is able to parse all reasonable date formats such as
  // YYYY/MM/DD, YY-MM-DD, MM/DD/YY, etc.
  return moment(date, localeSetting.dateFormat).format('YYYY-MM-DD');
}
