import { createContext, useContext, Context } from 'react';

export function createStrictContext<T>(
  defaultValue: T,
  options: {
    errorMessage?: string;
    name?: string;
  } = {}
) {
  const StrictContext = createContext<T>(defaultValue);
  StrictContext.displayName = options.name; // for DevTools

  function useStrictContext() {
    const context = useContext(StrictContext);
    if (context === undefined) {
      throw new Error(
        options.errorMessage ||
          `${options.name || ''} Context Provider is missing`
      );
    }
    return context;
  }

  return [StrictContext, useStrictContext] as [Context<T>, () => T];
}
